
import * as React from "react"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false
 


const Footer = () => {
    return (
        <footer>
            <div className="py-10 text-center text-xs text-gray-400 lg:py-18">
                <p>©{new Date().getFullYear()} STUDeO16</p>
            </div>
        </footer>
    )
}


export default Footer