import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLayerGroup,faEnvelope,faUser } from "@fortawesome/free-solid-svg-icons"
import Helmet from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Header = () => {
  return (   
    <div>
          <Helmet>
      <html lang="ja" />
      <title>STUDeO16</title>
      <meta name="description" content="沖縄でWeb・グラフィックのデザインやイラスト作成また写真・動画の撮影・編集を業務とするデザイナーのポートフォリオ" />
    </Helmet>
      <header class="fixed bottom-0 lg:top-0 w-full bg-white z-50 p-4 lg:p-5  lg:h-20" >
      <nav class=" flex justify-content">
        <div class="text-3xl tracking-normal hidden md:block text-gray-400 en"><AniLink swipe to="/">Studeo16</AniLink></div>
        <ul class="container flex items-center justify-between mx-auto text-gray-600 capitalize dark:text-gray-300   w-44 md:w-72">
          <li class="text-xl text-gray-400 text-center"><AniLink swipe class="block" to="/"><FontAwesomeIcon icon={faLayerGroup}/><br /><span class="text-xs hidden lg:block">library</span></AniLink></li>
          <li class="text-xl text-gray-400 text-center"><AniLink paintDrip hex="#ffffff" top="exit"  class="block" to="/about"><FontAwesomeIcon icon={faUser} /><br /><span class="text-xs hidden lg:block">about</span></AniLink></li>
          <li class="text-xl text-gray-400 text-center"><AniLink  cover bg="#ffffff" direction="right" class="block" to="/contact"><FontAwesomeIcon icon={faEnvelope} /><br /><span class="text-xs hidden lg:block">contact</span></AniLink></li>
        </ul>
    </nav>
    </header>
    </div>
  )
}

export default Header


